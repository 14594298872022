import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { FaChartLine, FaUsers, FaSearch, FaClock } from "react-icons/fa";
import Columns from "../components/columns";
import BenefitBlock from "../components/benefitBlock";
import SEO from "../components/seo";
import Lightbox from "../components/lightBox";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import TryButton from "../components/TryButton";

export const query = graphql`
  query {
    raporty: file(relativePath: { eq: "prosty-crm-raporty.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    raporty1: file(
      relativePath: {
        eq: "Raporty-w-prostym-systemie-CRM-aktywnosc-handlowcow.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1349, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    raporty2: file(
      relativePath: { eq: "Raporty-w-prostym-CRM-lejek-szans-sprzedazy.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1349, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    raporty3: file(
      relativePath: {
        eq: "Raporty-w-prostym-systemie-CRM-przyczyny-utraty.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1349, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Raporty = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Raporty sprzedażowe"
      headline="Analizuj wyniki działu sprzedaży"
    >
      <SEO
        title="Raporty w prostym CRM-ie - sprawna analiza sprzedaży"
        description="Raporty w CRM • Lejek sprzedaży, aktywność handlowców, przyczyny utraty szans • Analizuj i udoskonalaj proces sprzedaży • QuickCRM 30 dni za darmo!"
        keywords={["raporty"]}
      />
      <Columns>
        <div>
          <h3>
            <strong>Raporty w&nbsp;prostym systemie CRM? Oczywiście!</strong>
          </h3>
          <p>
            QuickCRM sam tworzy raporty, dotyczące Twojej sprzedaży. W&nbsp;ten
            sposób oszczędza czas, który możesz poświęcić na
            zadania&nbsp;ważniejsze niż żmudne zbieranie danych i&nbsp;ręczne
            tworzenie zestawień.
          </p>
          <p>
            <strong>Czego dotyczą raporty?</strong>
          </p>

          <p>
            <strong>
              Raporty to źródło najważniejszych informacji o&nbsp;całym procesie
              sprzedaży.
            </strong>
            &nbsp;Dotyczą kluczowych wskaźników, które wpływają na
            funkcjonowanie działu handlowego. W&nbsp;QuickCRM masz pewność, że
            będą one zawsze <strong>aktualne i&nbsp;poprawne</strong>. Nie
            doświadczysz pomyłek w&nbsp;obliczeniach czy opóźnień, związanych ze
            zbieraniem danych.
          </p>
        </div>
        <div>
        <br />
        <br />
        <Img
          style={{
            maxWidth: 800,
            margin: "0 auto"
          }}
          fluid={data.raporty.childImageSharp.fluid}
        />
        </div>
      </Columns>
      <br />
      <br />
      <br />
      <Lightbox images={[data.raporty1, data.raporty2, data.raporty3]} 
      alts={["raporty sprzedażowe w crm, raporty w crm, raporty do sprzedaży"]}/>
      <div>
      <br />

                              <br />
            <br />
        <h3>
          <strong>Jakie raporty znajdziesz w&nbsp;QuickCRM?</strong>
        </h3>
<br />
        <Columns>
          <div>
            <h3>Lejek szans sprzedaży</h3>
            <p>
              To raport, który pozwala
              <strong>na bieżąco monitorować przebieg kampanii</strong>. Dzięki
              niemu z&nbsp;łatwością będziesz mógł prognozować przychody.
              Zestawienie ukazuje
              <strong>
                liczbę szans na każdym z&nbsp;etapów oraz sumaryczną ich wartość
              </strong>
              . To podstawowa informacja, która jest niezbędna przy
              monitorowaniu przebiegu procesów sprzedażowych.
            </p>
          </div>
          <div>
            <h3>Konwersja lejka sprzedaży</h3>
            <p>
              Dzięki temu raportowi otrzymasz informację o&nbsp;tzw.
              <strong>wąskich gardłach</strong>, czyli obszarach, które należy
              udoskonalić, aby zwiększyć skuteczność sprzedaży. Pokaże Ci on,
              <strong>
                jaki procent szans przechodzi z&nbsp;jednego etapu do drugiego
              </strong>
              . Dzięki analizie tego raportu z&nbsp;łatwością wprowadzisz
              usprawnienia, które pozwolą Ci wyeliminować ograniczenia
              w&nbsp;Twoim procesie.
            </p>
          </div>
        </Columns>
<br />
<br />
        <Columns>
          <div>
            <h3>Przyczyny utraty</h3>
            <p>
              To raport, który zbiera informacje o&nbsp;tym,
              <strong>
                dlaczego klienci najczęściej rezygnują z&nbsp;zakupu.
              </strong>
              Po oznaczeniu szansy jako przegranej, handlowiec wpisuje
              przyczynę, a&nbsp;system zapisuje je w&nbsp;formie raportu. Dzięki
              temu wiesz,
              <strong>jakie są najsłabsze punkty Twojej oferty</strong>
              i&nbsp;możesz ją doskonalić w&nbsp;tym kierunku.
            </p>
          </div>
          <div>
            <h3>Aktywność handlowców</h3>
            <p>
              Twoi handlowcy wykonują wiele różnych zadań każdego dnia. Czy masz
              możliwość monitorowania ich aktywności? Raport aktywność
              handlowców pozwala Ci
              <strong>
                wyświetlić aktywności, które wykonywali w&nbsp;danym okresie
              </strong>
              . Pomoże Ci także ocenić,
              <strong>kto był najbardziej lub najmiej aktywny</strong>
              i&nbsp;jakie działania wykonywał.
            </p>
          </div>
        </Columns>
      </div>

      <h3
        style={{
          textAlign: "center"
        }}
      >
        <strong>Co zyskujesz?</strong>
      </h3>
      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaChartLine size="45px" />}
          content="Zawsze aktualne wyniki sprzedaży"
        />
        <BenefitBlock
          icon={<FaUsers size="45px" />}
          content="Narzędzie analizy skuteczności handlowców"
        />
        <BenefitBlock
          icon={<FaSearch size="45px" />}
          content="Szybką możliwość zdefiniowania ograniczeń"
        />
        <BenefitBlock
          icon={<FaClock size="45px" />}
          content="Czas, który możesz poświęcić na ważniejsze zadania"
        />
      </BenefitBlockWrapper>
    </FunctionPageLayout>
  );
};

export default Raporty;
