import React from "react";
import Layout from "./layout";
import Hero from "./hero";
import Container from "./container";
import styles from "./functionPageLayout.module.css";
import { FaArrowCircleRight } from "react-icons/fa";
import { Link } from "gatsby";
import TryButton from "./TryButton";
import MultiStepFormButton from "../components/MultiStepForm/MultiStepFormButton";

const FunctionPageLayout = ({ title, headline, children }) => {
  return (
    <Layout>
      <Hero maxHeight="280px" justifyContent="center">
        <h1>{title}</h1>
        <p>{headline}</p>
      </Hero>
      <Container>
        <div className={styles.contentWrapper}>{children}</div>
      </Container>
      <Hero maxHeight="200px" justifyContent="center">
        <p>Załóż bezpłatne konto i zacznij korzystać z systemu już teraz.</p>
<p
        style={{
          textAlign: "center"
        }}
      ><MultiStepFormButton></MultiStepFormButton></p>
      </Hero>
    </Layout>
  );
};

export default FunctionPageLayout;
