import React from "react";
import { Link } from "gatsby";
import { FaArrowCircleRight } from "react-icons/fa";
import styles from "../components/functionPageLayout.module.css";

const TryButton = () => {
return (
<Link style={{ width: "280px", margin:"0 auto" }} to="/demo-crm/" className={styles.startNowButton}>
<FaArrowCircleRight style={{ color: "white" }} /> ROZPOCZNIJ ZA DARMO
</Link>
);
};

export default TryButton;